/* 
BRENNER Alexander, GRILL Alexander @ 2020

genussvomfeld Website
*/

//Dependencies
import React from 'react';

//Stylesheets and Bootstrap
import { Container } from 'react-bootstrap';
import '../../Components_css/Product/product-simple.css';
import pumpkincalender from '../../img/calender/Kürbis.png'

//Components

class Strawberry extends React.Component {

    render() {
        return (
            <div className="prod-ctn-simple">

                <div className="parallax-container-product-simple" id="pumpkin"></div>
                <Container>
                    <div data-aos="fade-down" data-aos-delay="300">
                        <p className="display-4 prod-simple-head">Kürbisse</p>
                        <blockquote className="blockquote text-center">
                            <p className="mb-0 main-paragraph">
                                Beschreibung folgt in Kürze
                            </p>
                        </blockquote>
                    </div>

                    <div className="prod-calender">
                        <p className="display-4 prod-simple-head" id="saisontitle">Saisonkalender</p>
                        <img id="pumpkincalender" src={pumpkincalender} alt="pumpkincalender"></img>
                    </div>
                </Container>
            </div >
        );
    }


}

export default Strawberry;