/* 
BRENNER Alexander, GRILL Alexander @ 2020

genussvomfeld Website
*/

//Dependencies
import React from 'react';

//Stylesheets and Bootstrap
import { Container } from 'react-bootstrap';
import '../../Components_css/Product/product-simple.css';
import CheeryCarousel from '../Product/cheeryCarousel';
import CheeryCalender from '../../img/calender/Kirsche.png'

//Components

class Strawberry extends React.Component {

    render() {
        return (
            <div className="prod-ctn-simple">

                <div className="parallax-container-product-simple" id="cheery"></div>
                <Container>
                    <div data-aos="fade-down" data-aos-delay="300">
                        <p className="display-4 prod-simple-head">Kirschen</p>
                        <blockquote className="blockquote text-center">
                            <p className="mb-0 main-paragraph">

                                Auf unseren Streuobstwiesen wächst eine Vielfalt an Kirschbäumen, von Maikirschen bis spät reifende Sorten. Der größere Anteil wird als Frischware verkauft, die übrigen Früchte werden eingemaischt und zu Edelbränden destilliert.
                            </p>
                            <p className="mb-0 main-paragraph">
                                In unserem Hausgarten stehen ein halbes Dutzend Kirschbäume der Sorte Bigarreau Burlat. Dies ist eine frühe Herzkirschensorte, deren Früchte mittelgroß bis groß werden. Diese hochwertige Kirsche ist kein Massenprodukt. Nur mit großer Leidenschaft und dem nötigen Knowhow von Rudolf ist es möglich diese Kirschen zu kultivieren. Sie reifen Anfang Juni und werden mit äußerster Sorgfalt ebenfalls von Rudolf geerntet.
                            </p>
                            <p className="mb-0 main-paragraph">
                                „Die Kirschen in Nachbars Garten sind die Besten“
                            </p>
                            <p className="mb-0 main-paragraph">
                                „Mit mir ist heute nicht gut Kirschen essen“ „Wie Kinder und Beeren schmecken, muss man Kinder und Sperlinge fragen!“ – Johann Wolfgang von Goethe
                            </p>
                            <p className="mb-0 main-paragraph">
                                „Wie Kinder und Beeren schmecken, muss man Kinder und Sperlinge fragen!“ – Johann Wolfgang von Goethe
                            </p>

                        </blockquote>
                    </div>
                    <div id="carousel-wrap">
                        <div id="carousel">
                            <CheeryCarousel></CheeryCarousel>
                        </div>
                    </div>
                    <div className="prod-calender">
                        <p className="display-4 prod-simple-head" id="saisontitle">Saisonkalender</p>
                        <img id="cheerycalender" src={CheeryCalender} alt="cheerycalender"></img>
                    </div>

                </Container>
            </div >
        );
    }


}

export default Strawberry;