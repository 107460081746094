/* 
BRENNER Alexander, GRILL Alexander @ 2020

genussvomfeld Website
*/

import '../../Components_css/About/About.css';
import React, { useEffect } from 'react';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import FamilyPic from '../../img/familypic.png'
import TestPort from '../../img/Test_Portrai.png'

class About extends React.Component {

    render() {
        return (
            <div>
                <div className="team">
                    <h1 className="display-3 prod-simple-head">Des sein mia...</h1>
                    <div className="familypic">
                        <img id="familypic" src={FamilyPic} alt="familyPic"></img>
                    </div>
                </div>
                <Container>
                    <div className="news-entry" data-aos="fade-up">
                        <Row>
                            <Col lg="3">
                                <div data-aos="fade-right"
                                    data-aos-offset="300"
                                    data-aos-easing="ease-in-sine">
                                    {/*<img id="rudolfpic" src={TestPort} alt="testport"></img>*/}
                                </div>

                            </Col>

                            <Col lg="9" className="left-al">
                                <h1 id = "abouth" className="display-4">Rudolf</h1>
                                <p>
                                    Aufgewachsen am elterlichen Betrieb, kennt er die Arbeiten am Hof seit Kindheitstagen. Besuchte die
                                    Landwirtschaftliche Fachschule in Güssing. Heute geht er außerdem noch einer außerbetrieblichen
                                    Vollzeitbeschäftigung nach, doch die Freitzeit gehört der Landwirtschaft. Seine Aufgabenbereiche am
                                    Hof sind der Einkauf, der Pflanzenschutz, die Einteilung, die betrieblichen Aufzeichnungen und alle
                                    Arbeiten die anfallen. Seine Lieblingsbeschäftigung ist die Ausfahrt mit dem 760er Steyrer.
                                </p>
                            </Col>
                        </Row>
                    </div>

                    <div className="news-entry" data-aos="fade-up">
                        <Row>
                            <Col lg="9" className="right-al">
                                <h1 id = "abouth" className="display-4">Aurelia</h1>
                                <p>
                                    Tauschte als Wien Pendlerin im Jahr 2000 den Schreibtisch gegen das Erdbeerfeld. Sie ist seit 2010
                                    Betriebsführerin. Zu Ihren Aufgabenbereichen am Hof zählen die Ernte, die Vermarktung, das
                                    Finanzwesen und alle Arbeiten rund um die Spezialkulturen. Ihre Lieblingsbeschäftigung ist die Ernte
                                    von Obst und Gemüse.
                                </p>
                            </Col>

                            <Col lg="3">
                                <div data-aos="fade-left"
                                    data-aos-anchor="#example-anchor"
                                    data-aos-offset="500"
                                    data-aos-duration="2300">
                                    {/*<img id="rudolfpic" src={TestPort} alt="testport"></img>*/}
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <div className="news-entry" data-aos="fade-up">
                        <Row>
                            <Col lg="3" className="left-al">
                                <div data-aos="fade-right"
                                    data-aos-offset="300"
                                    data-aos-easing="ease-in-sine">
                                    {/*<img id="rudolfpic" src={TestPort} alt="testport"></img>*/}
                                </div>

                            </Col>

                            <Col lg="9">
                                <h1 id = "abouth" className="display-4">Rudolf</h1>
                                <p>
                                    Als Sohn der Familie hilft er stets am Betrieb mit. Beschäftigt sich sehr viel mit dem
                                    Pflanzenwachstum und bringt viele neue Ideen ein. Sein bisheriger Werdegang: Absolvent der HTL für
                                    Mechatronik und studiert derzeit Maschinenbau an der Montanuniversität in Leoben. Seine
                                    Seine Lieblingsbeschäftigung ist die Weiterbildung im Bereich des Pflanzenbaus.
                                </p>
                            </Col>
                        </Row>
                    </div>

                    <div className="news-entry" data-aos="fade-up">
                        <Row>
                            <Col lg="9" className="right-al">
                                <h1 id = "abouth" className="display-4">Thomas</h1>
                                <p>
                                    Zweiter Sohn der Familie und ebenfalls begeisteter Traktorfahrer. Er erledigt kleine Reparaturen,
                                    wartet Maschinen und Geräte und am Betrieb ist er der Logistiker. Absolvierte ebenfalls die HTL für
                                    Mechatronik. Seine Lieblingsbeschäftigung ist die Pflege der Streuobstwiesen.
                                </p>
                            </Col>

                            <Col lg="3">
                                <div data-aos="fade-left"
                                    data-aos-anchor="#example-anchor"
                                    data-aos-offset="500"
                                    data-aos-duration="2300">
                                    {/*<img id="rudolfpic" src={TestPort} alt="testport"></img>*/}
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        )
    }

}

export default About;