/* 
BRENNER Alexander, GRILL Alexander @ 2020

genussvomfeld Website
*/

//Dependencies
import React from 'react';

//Stylesheets and Bootstrap
import { Container } from 'react-bootstrap';
import '../../Components_css/Product/product-simple.css';
import StrawberryCarousel from '../Product/strawberryCarousel';
import StrawberryCalender from '../../img/calender/Erdbeere.png';


//Components

class Strawberry extends React.Component {

    render() {
        return (
            <div className="prod-ctn-simple">
                <div className="parallax-container-product-simple" id="strawberry">
                
                </div>
                


                <Container>
    
                    <div data-aos="fade-down" data-aos-delay="300">
                        <p className="display-4 prod-simple-head">Erdbeeren</p>
                        <blockquote className="blockquote text-center">
                            <p className="mb-0 main-paragraph">
                            Die Erdbeere (Fragaria ananassa) wird schon seit mehr als 100 Jahren in unserer Region kultiviert (Nachbargemeinde Wiesen), sowie auch auf unserem Betrieb in der dritten Generation. 
                            Wir bevorzugen den Anbau von Frigopflanzen der Sorten Clery, Dream und Elianny im Juli. 
                            </p>
                            <p className="mb-0 main-paragraph">
                            Die Arbeitsschritte bei der Produktion der Erdbeeren auf unserem Betrieb werden in groben Schritten so ausgeführt: Auswahl des Feldstückes, Bestimmung der Vorfrucht, mechanische Bodenbearbeitung unter Berücksichtigung eines garen Bodens, Pflanzung, Bewässerung, Entfernung von Unkräutern, Pflanzenschutz, Schutz vor Wildverbiss mit Netz, Stroheinstreuung (Stroh von unseren Weizenfeldern) und die Ernte.
                            Je nach Witterung beginnen wir mit der Ernte Mitte Mai. Reife und einwandfreie Früchte werden mit äußerster Sorgfalt geerntet und behutsam in Spankörbe geschlichtet. Unsere beiden Verkaufsstände werden mehrmals täglich mit frisch gepflückten Erdbeeren befüllt. 
                            </p>
                            <p className="mb-0 main-paragraph">
                            Am besten schmecken die Erdbeeren frisch gepflückt. Falls man die Früchte jedoch weiterverarbeiten möchte, gibt es hier einige Beispiele: in einem Obstsalat, gemischt mit Joghurt, Sauerrahm oder Eiscreme, als Tortenbelag, in einer Erdbeerbowle, als Fruchtaufstrich (Marmelade) oder frisch eingefroren bereiten sie noch im Winter eine tolle und fruchtige Überraschung als Erdbeermus.
                            </p>

                        </blockquote>
                    </div>

                    <div id="carousel-wrap">
                        <div id="carousel">
                            <StrawberryCarousel></StrawberryCarousel>
                        </div>
                    </div>

                    

                    <div className="prod-calender">
                        <p className="display-4 prod-simple-head" id="saisontitle">Saisonkalender</p>
                        <img id="strawberrycalender" src={StrawberryCalender} alt="strawberrycalender"></img>
                    </div>
                </Container>

                

            </div >
        );
    }


}

export default Strawberry;