/* 
BRENNER Alexander, GRILL Alexander @ 2020

genussvomfeld Website
*/

//Dependencies
import React from 'react';

//Stylesheets and Bootstrap
import { Container } from 'react-bootstrap';
import '../../Components_css/Product/product-simple.css';
import zuchinicalender from '../../img/calender/Zucchini.png'

//Components

class Strawberry extends React.Component {

    render() {
        return (
            <div className="prod-ctn-simple">

                <div className="parallax-container-product-simple" id="zucchini"></div>
                <Container>
                    <div data-aos="fade-down" data-aos-delay="300">
                        <p className="display-4 prod-simple-head">Zucchini</p>
                        <blockquote className="blockquote text-center">
                            <p className="mb-0 main-paragraph">
                                Die Zucchini sind eine Unterart des Gartenkürbisses und gehören zur Pflanzenfamilie der Kürbisgewächse. Zucchini sind einhäusig, das heißt, männliche und weibliche Geschlechtsorgane befinden sich in getrennten Blüten, aber an einer Pflanze. Es gibt Zucchini mit weißen, gelben, creme-grünen, dunkelgrünen sowie gestreiften Früchten in länglichen und runden Formen. Wir bevorzugen den Anbau von grünen und gelben Zucchini in länglicher Form.
                            </p>
                            <p className="mb-0 main-paragraph">
                                Da Zucchini frostempfindlich sind, werden diese nicht vor Mitte Mai, also nach den Eisheiligen, ins Beet gesetzt. Ein warmer und sonniger Standort ist ideal. Gepflanzt werden sie auf einem nährstoffreichen Boden im Abstand von ca. 1,5 m, da sie viel Platz benötigen. Je nach Wetterlage sind die ersten Zucchini nach etwa zwei Monaten reif. Die jungen Früchte werden mit einem scharfen Messer etwa ein bis zwei Zentimeter hinter dem Stielansatz abgeschnitten. Regelmäßiges Ernten regt die Pflanze dazu an, weitere Zucchini auszubilden.
                            </p>
                            <p className="mb-0 main-paragraph">
                            Werden Zucchini bei einer Länge von 10 bis 20 cm geerntet, ergeben sie ein zartes Gemüse. Da es von Kunden gewünscht wird, bieten wir auch Zucchini in großen Größen an. Übrigens sind auch die gelben Blüten der Zucchini essbar. Sie schmecken beispielsweise sehr gut in Öl frittiert. Geheimtipp: Blüten bei uns bestellen und Ihre Familie damit verwöhnen. Verwöhnen könnt ihr euch aber auch mit einem saftigen Zucchinikuchen.
                            </p>

                        </blockquote>
                    </div>
                    <div className="prod-calender">
                        <p className="display-4 prod-simple-head" id="saisontitle">Saisonkalender</p>
                        <img id="zuchinicalender" src={zuchinicalender} alt="zuchinicalender"></img>
                    </div>
                </Container>
            </div >
        );
    }


}

export default Strawberry;