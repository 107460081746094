/* 
BRENNER Alexander, GRILL Alexander @ 2020

genussvomfeld Website
*/

import React from 'react';
import '../../Components_css/Loader/Loader.css';
import Traktor from "../../img/Loader/traktor_grundaufbau.png"
import Rad from '../../img/Loader/rad.png'


class Loader extends React.Component {

    render() {
        return (
            <div id="loader-ctn">
                <div className="traktor-ctn">
                    <img id = "traktor" src={Traktor} alt="traktorPic"></img>
                    <img id = "radhinten" src={Rad} alt="radHinten"></img>
                    <img id = "radvorne" src={Rad} alt="radVorne"></img>
                </div>
               
            </div>
        )
    }
}

export default Loader;