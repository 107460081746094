/* 
BRENNER Alexander, GRILL Alexander @ 2020

genussvomfeld Website
*/

import React from 'react';
import '../../Components_css/Contact/Contact.css';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Alert from 'react-bootstrap/Alert'
import axios from 'axios';

import Map from '../Maps/map'
import MapTwo from '../Maps/map_two'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faIdCardAlt } from '@fortawesome/free-solid-svg-icons'

import $ from "jquery"

const API_PATH = 'https://www.genussvomfeld.at/api/contact/index.php';

class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            message: '',
            mailSent: false,
            error: null
        }
    }

    componentWillMount() {
        $("#button").show();
        $("#user-feedback").css("visibility", "hidden")
    } 

    handleFormSubmit = e => {
        e.preventDefault();
        axios({
            method: 'post',
            url: `${API_PATH}`,
            headers: { 'content-type': 'application/json' },
            data: this.state
        })
            .then(
                result => {
                this.setState({
                    mailSent: result.data.sent
                })

                if(($("#name").val() != "" && $("#email").val() != "" && $("#message").val() != "")){
                    $("#button").hide();
                $("#user-feedback").css("visibility", "visible")
                } 
                
            })
            .catch(error => this.setState({ error: error.message }));
    };

    render() {
        return (
            <div id="contact-wrap">
                <Container id="contact-section">
                    <Row>
                        <Col lg="4"></Col>
                        <Col lg="4">
                            <div className="overview-card contact-div" data-aos="fade-right" data-aos-delay="300">
                                <FontAwesomeIcon icon={faIdCardAlt} className="icon-overview" />
                                <h3 className="overview-heading">Kontakt</h3>
                                <p>Aurelia Bierbaumer</p>
                                <p>Hochbergstraße 104</p>
                                <p>7212 Forchtenstein</p>
                                <p>Tel: 02626 836 24</p>
                                <p>Mobil: 0664 5364 094</p>
                                <p>Email: info@genussvomfeld.at</p>
                            </div>
                        </Col>
                        <Col lg="4"></Col>
                    </Row>
                </Container>

                <div data-aos="zoom-in" className="parallax-container-vh">
                    <div id="txt-contact-container"><span className="my-text-news" id="anfahrttitle2">Verkaufstand am Hof</span></div>
                </div>

                <Container >
                    <div data-aos="fade-up" className="contact-text">
                        <Row>
                            <Col lg={4} >
                                <div className="my-map-wrap">
                                    <Map></Map>
                                </div>
                            </Col>

                            <Col lg={8}>
                                <div className="map-desc">
                                    <h1 className="display-4 contact-headline" id="anfahrttitle">Anfahrt - Hof</h1>
                                    <p>
                                        Unser Verkaufsstand befindet sich in der Hochbergstraße 104 direkt vor unserem Wohnhaus mit
                                        dahinterliegender Geräte- und Maschinenhalle. Der Stand wird mehrmals täglich frisch befüllt. Ab
                                        der Erdbeerernte Mitte Mai bis zur Kürbisernte im Oktober können sie täglich (Montag bis Sonntag)
                                        unsere Produkte erwerben. Sie treffen Ihre Auswahl und bezahlen durch Geldeinwurf in eine fix
                                        montierte Geldkassa. Die Preisekennzeichnung entnehmen sie den kleinen Kreidetafeln. Sie tätigen
                                        selbständig ihren Einkauf bei Fragen oder Bestellung nehmen sie einfach Kontakt mit uns auf.
                                    </p>

                                </div>
                            </Col>
                        </Row>


                    </div>
                </Container>

                <div data-aos="zoom-in" className="parallax-container-ve">
                    <div id="txt-contact-container"><span className="my-text-news" id="anfahrttitle2">Verkaufstand zur Erdbeersaison</span></div>
                </div>

                <Container>
                    <div data-aos="fade-up" className="contact-text">
                        <Row>
                            <Col lg={8} >
                                <div className="map-desc my-right-align">
                                    <h1 className="display-4 contact-headline" id="anfahrttitle">Anfahrt - Erdbeersaison</h1>
                                    <p>
                                        Unser zweiter Verkaufsstand befindet sich am Parkplatz vor dem Fußballplatz Forchtenstein zeitlich
                                        begrenzt nur in der Erdbeersaison (Ende Mai bis Juni). In dieser Zeit können Sie zwischen 9.00 und
                                        18.00 Uhr unsere Produkte kaufen.
                                    </p>

                                </div>
                            </Col>
                            <Col lg={4} >
                                <div className="my-map-wrap">
                                    <MapTwo></MapTwo>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>


                <div className="contact-container" id="form">
                    <h1 className="contact-headline">Kontakformular</h1><br></br>
                    <form action="/action_page.php" className="was-validated" onSubmit="return false">

                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1">Name</span>
                            </div>
                            <input type="text" className="form-control" id="name" name="name" aria-label="Username" aria-describedby="basic-addon1" required
                                value={this.state.name}
                                onChange={e => this.setState({ name: e.target.value })}
                            ></input>
                            <div className="valid-feedback"></div>
                            <div className="invalid-feedback">Benötigtes Feld</div>
                        </div>

                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1">@</span>
                            </div>
                            <input type="email" className="form-control" id="email" name="email" aria-label="Username" aria-describedby="basic-addon1" required
                                value={this.state.email}
                                onChange={e => this.setState({ email: e.target.value })}>
                            </input>
                            <div className="valid-feedback"></div>
                            <div className="invalid-feedback">Benötigtes Feld</div>
                        </div>

                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text">Nachricht</span>
                            </div>
                            <textarea id="mes" className="form-control" id="message" name="message" aria-label="With textarea" required
                                onChange={e => this.setState({ message: e.target.value })}
                                value={this.state.message}>
                            </textarea>
                        </div>

                        <button className="my-button" type="submit" id="button" value="Senden" onClick={e => this.handleFormSubmit(e)}>Absenden</button>

                    </form>

                    <Alert id="user-feedback" variant="success">Danke für ihre Nachricht!</Alert>


                </div>



            </div>
        );
    }
}

export default Contact;