/* 
BRENNER Alexander, GRILL Alexander @ 2020

genussvomfeld Website
*/

//Dependencies
import React from 'react';

//Stylesheets and Bootstrap
import { Container } from 'react-bootstrap';
import '../../Components_css/Product/product.css';

//Components

class product extends React.Component {

    render() {
        return (
            <div id="product-ctn">
                <Container>
                    <div data-aos="fade-down" data-aos-delay="300">
                        <p className="display-4 product-heading">Unsere Produkte</p>
                    </div>

                    <a href="/product/erdbeeren"><div className="parallax-container-product" id="strawberry" data-aos="fade-up"><span className="text-container-product">Erdbeeren</span></div></a>
                    <a href="/product/kirschen"><div className="parallax-container-product" id="cheery" data-aos="fade-up"><span className="text-container-product">Kirschen</span></div></a>
                    <a href="/product/zucchini"><div className="parallax-container-product" id="zucchini" data-aos="fade-up"><span className="text-container-product">Zucchini</span></div></a>
                    <a href="/product/fisolen"><div className="parallax-container-product" id="fisoles" data-aos="fade-up"><span className="text-container-product">Fisolen</span></div></a>
                    <a href="/product/kartoffeln"><div className="parallax-container-product" id="potato" data-aos="fade-up"><span className="text-container-product">Kartoffeln</span></div></a>
                    <a href="/product/kuerbisse"><div className="parallax-container-product" id="pumpkin" data-aos="fade-up"><span className="text-container-product">Kürbisse</span></div></a>
                    <a href="/product/rueben"><div className="parallax-container-product" id="beet" data-aos="fade-up"><span className="text-container-product">Rüben</span></div></a>
                    <a href="/product/edelbraende"><div className="parallax-container-product" id="liquor" data-aos="fade-up"><span className="text-container-product">Edelbrände</span></div></a>
                   
                </Container>
            </div >
        );
    }


}

export default product;