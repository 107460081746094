/* 
BRENNER Alexander, GRILL Alexander @ 2020

genussvomfeld Website
*/

import React, { Component } from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

export class MapContainer extends Component {
    render() {
        return (
            <Map
                google={this.props.google}
                zoom={14}
                initialCenter={{
                    lat: 47.725147,
                    lng: 16.347602
                }}
            >
                <Marker
                    name={'Verkaufsstand - Hof'}
                />
            </Map>

        );
    }
}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyBT9OP30F99hB_nb-nx41kzgFGD7DbdC8Y'
})(MapContainer);