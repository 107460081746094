/* 
BRENNER Alexander, GRILL Alexander @ 2020

genussvomfeld Website
*/

//Dependencies
import React from 'react';

//Stylesheets and Bootstrap
import '../../Components_css/Heading/heading.css';
import instalogo from '../../img/Instagram.png'
//Components

class Heading extends React.Component {

    render() {
        return (
            <div>
                <div className="heading-container">
                    <div className="inner-footer">
                        <div className="heading-content-wrapper">
                            <p className="heading-text">Tel.: +43 664 5364094</p><p className="heading-text">|</p><a href="/contact"className="heading-text">info@genussvomfeld.at</a>
                            <a href="https://www.instagram.com/genussvomfeld"  target="_blank" ><img  id="instalogo" src={instalogo} alt="instaLogo"></img></a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Heading;