/* 
BRENNER Alexander, GRILL Alexander @ 2020

genussvomfeld Website
*/

//Dependencies
import React from 'react';

//Stylesheets and Bootstrap
import { Container } from 'react-bootstrap';
import '../../Components_css/Product/product-simple.css';
import potatocalender from '../../img/calender/Kartoffel.png'

//Components

class Strawberry extends React.Component {

    render() {
        return (
            <div className="prod-ctn-simple">

                <div className="parallax-container-product-simple" id="potato"></div>
                <Container>
                    <div data-aos="fade-down" data-aos-delay="300">
                        <p className="display-4 prod-simple-head">Früh- und Lagerkartoffeln</p>
                        <blockquote className="blockquote text-center">
                            <p className="mb-0 main-paragraph">
                                Frühe Kartoffeln: Vorwiegend festkochend, nicht zum Lagern geeignet   
                            </p>
                            <p className="mb-0 main-paragraph">
                                Späte Kartoffeln: Bestehen ausschließlich aus festkochenden und gut lagerfähigen Sorten.
                            </p>
                            <p className="mb-0 main-paragraph">
                                Die Kartoffel, umgangssprachlich auch oft als Erdäpfel bezeichnet, wird seit jeher auf unserem Betrieb angebaut. Die Zeiten der Kartoffel wandelten sich vom Grundnahrungs- und Futtermittel bis heute als Basis für die vielfältigsten Kartoffelgerichte. Zudem sind unsere Kartoffeln handverlesen.
                                Der Grundstein für eine gesunde Kartoffel wird schon bei der Pflanzung gelegt. Bei der Pflanzung ist ein gut gelockertes Saatbeet die Voraussetzung um einen Kartoffeldamm aufzuschütten und so günstige Bedingungen für die Kartoffel zu schaffen. In der Bestandsführung ist der Pflanzenschutz dabei von besonderer Bedeutung. Dabei muss die Pflanze vor Kartoffelkäfern, Drahtwürmern und Pilzen, die Auslöser von Schorf und Krautfäule sind, beschützt werden.
                                Früher erfolgte die Ernte der Kartoffel ausschließlich per Hand, welche für die Kartoffel zwar schonender ist, jedoch sehr arbeitsintensiv. Heute erfolgen nur mehr die ersten Sätze der Frühkartoffelernte mit Hand, um die Schale der Kartoffeln zu schonen. Ist die Schalenfestigkeit gewährleistet, wird die Ernte maschinell durchgeführt.
                            </p>
                            <p className="mb-0 main-paragraph">    
                                Die Ernte der Frühkartoffel kann durch Fließeinsatz, ähnlich wie bei Erdbeeren, um etwa zwei Wochen verfrüht werden und so stehen ab Anfang Juli die ersten Heurigen zum Verzehr bereit.
                                Die Haupternte der wohlschmeckenden Knollen findet im September statt. Dafür werden ausschließlich lagerfähige Sorten angebaut.
                                Optimale Lagerungsbedingungen findet die Kartoffel im Erdkeller.
                            </p>
                            <h5>
                                Warum sind die Knollen so schmutzig?
                            </h5>
                            <p className="mb-0 main-paragraph">
                                Dafür gibt es eine einfache Antwort: Die Haltbarkeit.
                                Würde man die Knollen vor dem Verkauf waschen, wäre die Lagerfähigkeit nicht mehr gegeben und die Knolle würde anfangen zu keimen. Zudem würde das Waschen den Aufwand der Kartoffelproduktion steigern und somit auch der Preis.
                            </p>

                        </blockquote>
                    </div>

                    <div className="prod-calender">
                        <p className="display-4 prod-simple-head" id="saisontitle">Saisonkalender</p>
                        <img id="potatocalender" src={potatocalender} alt="potatocalender"></img>
                    </div>
                </Container>
            </div >
        );
    }


}

export default Strawberry;