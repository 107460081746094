/* 
BRENNER Alexander, GRILL Alexander @ 2020

genussvomfeld Website
*/

//Dependencies
import React from 'react';

//Stylesheets and Bootstrap
import { Container } from 'react-bootstrap';
import '../../Components_css/Product/product-simple.css';
import FisolesCarousel from '../Product/fisolesCarousel';
import FisolesCalender from '../../img/calender/Fisolen.png'

//Components

class Strawberry extends React.Component {

    render() {
        return (
            <div className="prod-ctn-simple">

                <div className="parallax-container-product-simple" id="fisoles"></div>
                <Container>
                    <div data-aos="fade-down" data-aos-delay="300">
                        <p className="display-4 prod-simple-head">Fisolen</p>
                        <blockquote className="blockquote text-center">
                            <p className="mb-0 main-paragraph">
                                Die Fisolen, beziehungsweise Buschbohnen, sind seit Jahrzehnten in unserem Gemüsegarten anzutreffen. In den letzten Jahren entwickelte sich ein regelrechter Boom um die gelben „Stangen“. Dabei kamen die Fisolen vom Gemüsegarten an einen eigenen Standort. Die Pflanze findet bei uns optimale Bedingungen um gut gedeihen zu können. Dabei bieten besonders das warme pannonische Klima und die günstigen Niederschläge des Alpenvorlandes die optimalen Voraussetzungen für eine gute Ernte.
                            </p>
                            <p className="mb-0 main-paragraph">
                                 Sommerzeit ist Fisolenzeit, ab Anfang Juli sind bei uns die ersten Fisolen reif, die besonders am Anfang heiß begehrt sind. Beispielsweise sind Fisolen eine schmackhafte Beilage zu Ihrem Grillfest. Man verwendet sie auch für den klassischen Fisolen Salat, für Speckfisolen oder wie man bei uns sagt; als „Zuaspeis“ . 
                            </p>
                        </blockquote>
                    </div>

                    <div id="carousel-wrap">
                        <div id="carousel">
                            <FisolesCarousel></FisolesCarousel>
                        </div>
                    </div>

                    <div className="prod-calender">
                        <p className="display-4 prod-simple-head" id="saisontitle">Saisonkalender</p>
                        <img id="fisolscalender" src={FisolesCalender} alt="fisolscalender"></img>
                    </div>
                </Container>
            </div >
        );
    }


}

export default Strawberry;