/* 
BRENNER Alexander, GRILL Alexander @ 2020

genussvomfeld Website
*/

//Dependencies
import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AOS from 'aos'

//Stylesheets and Bootstrap
import './App.css';

//Components
import Home from './Components/Home/Home'
import News from './Components/News/news'
import Nav from './Components/Nav/Nav'
import Heading from './Components/Heading/Heading'
import EndSection from './Components/End/End'
import Loader from "./Components/Loader/Loader"
import Contact from "./Components/Contact/Contact"
import About from './Components/About/About'
import Product from './Components/Product/product'
import Dsgvo from './Components/dsgvo/dsgvo'

import Strawberry from './Components/Product/strawberry'
import Potato from './Components/Product/potato'
import Fisoles from './Components/Product/fisoles'
import Zucchini from './Components/Product/zucchini'
import Pumpkin from './Components/Product/pumpkin'
import Beet from './Components/Product/beet'
import Cheery from './Components/Product/cheery'
import Liquor from './Components/Product/liquor'

import $ from "jquery"



class App extends React.Component {

    constructor(props){
        super(props);
        this.timer = null
    }
  
    componentDidMount() {
      this.timer = setTimeout(() => {
          $("#l-main").css("display", "none")
          $("#cnt-wrap-all").css("display", "block")
      }, 600)
    }
    
    componentWillUnmount() {
      clearTimeout(this.timer);
    }

    componentWillMount(){
        AOS.init();

    }

    render() {
        return (
            <div>

                <link
                    rel="stylesheet"
                    href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css"
                    integrity="sha384-9aIt2nRpC12Uk9gS9baDl411NQApFmC26EwAOH8WgZl5MYYxFfc+NcPb1dKGj7Sk"
                    crossOrigin="anonymous"
                />
                <link href="https://unpkg.com/aos@2.3.1/dist/aos.css" rel="stylesheet"></link>
                <script src="https://unpkg.com/aos@2.3.1/dist/aos.js"></script>

                <div id="l-main"><Loader></Loader></div>

                <div id="cnt-wrap-all">
                    <Nav></Nav>
                    <Heading></Heading>
                    <Router>
                        <Switch>
                            <Route path="/news">
                                <News></News>
                            </Route>


                            <Route path="/product/erdbeeren">
                                <Strawberry></Strawberry>
                            </Route>
                            <Route path="/product/kartoffeln">
                                <Potato></Potato>
                            </Route>
                            <Route path="/product/fisolen">
                                <Fisoles></Fisoles>
                            </Route>
                            <Route path="/product/zucchini">
                                <Zucchini></Zucchini>
                            </Route>
                            <Route path="/product/kuerbisse">
                                <Pumpkin></Pumpkin>
                            </Route>
                            <Route path="/product/rueben">
                                <Beet></Beet>
                            </Route>
                            <Route path="/product/kirschen">
                                <Cheery></Cheery>
                            </Route>
                            <Route path="/product/edelbraende">
                                <Liquor></Liquor>
                            </Route>


                            <Route path="/product">
                                <Product></Product>
                            </Route>
                            <Route path="/about">
                                <About></About>
                            </Route>
                            <Route path="/contact">
                                <Contact></Contact>
                            </Route>
                            <Route path="/dsgvo">
                                <Dsgvo></Dsgvo>
                            </Route>
                            <Route path="/">
                                <Home></Home>
                            </Route>
                        </Switch>
                    </Router>

                    <EndSection></EndSection>

                    <script src="https://unpkg.com/react/umd/react.production.min.js" crossOrigin="true"></script>
                    <script src="https://unpkg.com/react-dom/umd/react-dom.production.min.js" crossOrigin="true"></script>
                    <script src="https://unpkg.com/react-bootstrap@next/dist/react-bootstrap.min.js" crossOrigin="true"></script>
                
                </div>
                
            </div>
        );
    }
}

export default App;