/* 
BRENNER Alexander, GRILL Alexander @ 2020

genussvomfeld Website
*/

//Dependencies
import React from 'react';

//Stylesheets and Bootstrap
import '../../Components_css/News/news.css';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import news1 from "../../img/news/news_1.png"
import news2 from "../../img/news/news_2.png"
import news3 from "../../img/news/news_3.jpg"
import news4 from "../../img/news/news_4.png"
import news5 from "../../img/news/news_5.png"
//Components

class News extends React.Component {

    render() {
        return (
            <div>
                <div className="parallax-container-news">
                    <div id="txt-news-container"><span className="my-text-news">Neuigkeiten</span></div>
                </div>

                <Container>
                    <div className="news-entry" data-aos="fade-up">
                        <Row>
                            <Col lg="3">
                                <img className="news-img" src={news5} alt="news 5"></img>
                            </Col>

                            <Col lg="9">
                                <h1 className="display-4">Mulchschicht bei den Kartoffeln!</h1>
                                <p>Veröffentlicht am: 18.06.2021</p>
                                <p>Während die Erdbeerernte in vollem Gange ist wird bei den Kartoffeln eine Mulchschicht aus gehäckseltem Wickroggen auf den Kartoffeldämmen ausgebracht, um diese vor Wärme und dem Austrocknen zu schützen.
                                    Dies ist ein kleiner Schritt in Richtung regenerative Landwirtschaft (die Landwirtschaft der Zukunft).
                                </p>
                            </Col>
                        </Row>
                    </div>
                    <div className="news-entry" data-aos="fade-up">
                        <Row>
                            <Col lg="3">
                                <img className="news-img" src={news4} alt="news 4"></img>
                            </Col>

                            <Col lg="9">
                                <h1 className="display-4">Die Erbeersaison ist für 2021 eröffnet!</h1>
                                <p>Veröffentlicht am: 11.06.2021</p>
                                <p>Mit diesem Wochenende starten wir endlich in die Erbeersaison. Unser Verkaufsstand wird täglich ab 9 Uhr laufend mit frischen Erdbberen versorgt.</p>
                            </Col>
                        </Row>
                    </div>
                    <div className="news-entry" data-aos="fade-up">
                        <Row>
                            <Col lg="3">
                                <img className="news-img" src={news3} alt="news 3"></img>
                            </Col>

                            <Col lg="9">
                                <h1 className="display-4">Wir beregnen fleißig unserer Erdbeerfelder!</h1>
                                <p>Veröffentlicht am: 10.05.2021</p>
                                <p>Auf Grund des geringen Niederschlags im Frühjahr benötigen die Erdbeerpflanzen derzeit zusätzliches Wasser um ein optimales Pflanzenwachstum zu erreichen.</p>
                            </Col>
                        </Row>
                    </div>
                    <div className="news-entry" data-aos="fade-up">
                        <Row>
                            <Col lg="3">
                                <img className="news-img" src={news2} alt="news 2"></img>
                            </Col>

                            <Col lg="9">
                                <h1 className="display-4">Kartoffeln legen 2021!</h1>
                                <p>Veröffentlicht am: 08.05.2021</p>
                                <p>Ende April wurden die letzten Speisekartoffeln 2021 versteckt.</p>
                            </Col>
                        </Row>
                    </div>
                    <div className="news-entry" data-aos="fade-up">
                        <Row>
                            <Col lg="3">
                                <img className="news-img" src={news1} alt="news 1"></img>
                            </Col>

                            <Col lg="9">
                                <h1 className="display-4">Herstellung unserer Edelbrände!</h1>
                                <p>Veröffentlicht am: 04.01.2021</p>
                                <p>In den Wintermonaten verarbeiten wir das übrige Obst unserer Streuobstwiesen zu hochwertigen Edelbränden.</p>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        );
    }
}

export default News;