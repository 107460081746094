/* 
BRENNER Alexander, GRILL Alexander @ 2020

genussvomfeld Website
*/

//Dependencies
import React from 'react';

//Stylesheets and Bootstrap
import '../../Components_css/Nav/Nav.css';
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import logo from '../../img/Genuss_vom_Feld_Logo.png'
import $ from "jquery"

//Components

class myNav extends React.Component {

    componentDidMount() {
        var path = window.location.pathname;
        $(".my-nav-link[href='" + path + "']").addClass("my-nav-link-active")
    }

    render() {
        return (
            <div id="nav-ctn">
                <Navbar id="nav-wrap" collapseOnSelect expand="lg" bg="light" variant="light" fixed='top'>
                    <Navbar.Brand href="/"><img src={logo} alt="Bierbaumer Logo" className="nav-logo"></img></Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mr-auto">
                            <Nav.Link href="/" className="my-nav-link"><span data-hover="Home">Home</span></Nav.Link>
                            <Nav.Link href="/news" className="my-nav-link"><span data-hover="Neuigkeiten">Neuigkeiten</span></Nav.Link>
                            <NavDropdown title="Produkte" className="my-nav-drop">
                                <NavDropdown.Item className="my-drop-item" href="/product">Unsere Produkte</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item className="my-drop-item" href="/product/erdbeeren">Erdbeeren</NavDropdown.Item>
                                <NavDropdown.Item className="my-drop-item" href="/product/kirschen">Kirschen</NavDropdown.Item>
                                <NavDropdown.Item className="my-drop-item" href="/product/zucchini">Zucchini</NavDropdown.Item>
                                <NavDropdown.Item className="my-drop-item" href="/product/fisolen">Fisolen</NavDropdown.Item>
                                <NavDropdown.Item className="my-drop-item" href="/product/kartoffeln">Kartoffeln</NavDropdown.Item>
                                <NavDropdown.Item className="my-drop-item" href="/product/kuerbisse">Kürbisse</NavDropdown.Item>
                                <NavDropdown.Item className="my-drop-item" href="/product/rueben">Rüben</NavDropdown.Item>
                                <NavDropdown.Item className="my-drop-item" href="/product/edelbraende">Edelbrände</NavDropdown.Item>
                                <NavDropdown.Item className="my-drop-item" href="https://shopping-dahoam.at/?s=bierbaumer&lang=de" target="_blank">Online Shop</NavDropdown.Item>
                            </NavDropdown> 
                            <Nav.Link href="/about" className="my-nav-link"><span data-hover="Über uns">Über uns</span></Nav.Link>
                            <Nav.Link href="/contact" className="my-nav-link"><span data-hover="Kontakt">Kontakt</span></Nav.Link>
                   
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </div >
        );
    }


}

export default myNav;