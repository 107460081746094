/* 
BRENNER Alexander, GRILL Alexander @ 2020

genussvomfeld Website
*/

//Dependencies
import React from 'react';

//Stylesheets and Bootstrap
import Carousel from 'react-bootstrap/Carousel'
import "../../Components_css/Home/home.css"
import pic1 from '../../img/products/cheery/Kirschen_1.jpg'
import pic2 from '../../img/products/cheery/Kirschen_2.jpg'
import pic3 from '../../img/products/cheery/Kirschen_3.jpg'
import pic4 from '../../img/products/cheery/Kirschen_4.jpg'
import pic5 from '../../img/products/cheery/Kirschen_5.jpg'

//Components

class cheeryCarousel extends React.Component {

    render() {
        return (
            <div>
                <Carousel>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={pic1}
                            alt="Third slide"
                        />
                    </Carousel.Item>

                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={pic2}
                            alt="Third slide"
                        />
                    </Carousel.Item>

                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={pic3}
                            alt="Third slide"
                        />
                    </Carousel.Item>

                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={pic4}
                            alt="Third slide"
                        />
                    </Carousel.Item>

                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={pic5}
                            alt="Third slide"
                        />
                    </Carousel.Item>
                </Carousel>
            </div>
        );
    }
}

export default cheeryCarousel;