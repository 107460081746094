/* 
BRENNER Alexander, GRILL Alexander @ 2020

genussvomfeld Website
*/

//Dependencies
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons'
import { faClock } from '@fortawesome/free-solid-svg-icons'
import { faBraille } from '@fortawesome/free-solid-svg-icons'

//Stylesheets and Bootstrap
import '../../Components_css/Home/home.css';
import MyCarousel from './MyCarousel';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import logo from '../../img/Genuss_vom_Feld_Logo.png'

//Components

class Home extends React.Component {

    render() {
        return (
            <div id="home-wrap">
                <MyCarousel></MyCarousel>
                <Container>
                    <div className="main-section">
                        <div data-aos="fade-up" data-aos-delay="300"><img src={logo} alt="Bierbaumer Logo" className="logo-main" /></div>
                        <div data-aos="fade-down" data-aos-delay="300">
                            <p className="display-4">Unseren Betrieb möchten wir ihnen anhand unseres Logos vorstellen</p>
                            <blockquote className="blockquote text-center">
                                <p className="mb-0 main-paragraph">
                                    Für unseren Familiennamen haben wir die Farbe grün gewählt, da wir mit, für und von der Natur
                                    leben. All unsere Felder und Streuobstwiesen liegen an den grünen Hängen des Rosaliengebirges.
                                    Grün ist die Farbe der Hoffnung, denn wir hoffen immer auf gutes Wetter, damit unsere Pflanzen gut
                                    gedeihen.
                                </p>
                                <p className="mb-0 main-paragraph">
                                    In unserem Motto „Genuss vom Feld“ steht das Feld für unsere Böden, die uns sehr am Herzen
                                    liegen. Nur ein ausreichend versorgter, gut bearbeiteter Boden bietet den Grundstock für ein
                                    prächtiges Pflanzenwachstum.
                                    Genuss bieten sollen all die Feldfrüchte die wir ernten. Als Symbole dafür haben wir die Erdbeere,
                                    einen Kürbis und eine Rübe ausgewählt.
                                    Die Erdbeere, der Genuss schlechthin, steht einerseits für eine lange Familientradition und
                                    andererseits für den Beginn in unserem Erntezyklus. Der Kürbis als Speise-, Zier und Halloween-
                                    Kürbis in seinen vielen Formen und Farben steht für die Vielfalt unserer Produkte. Denn wir bauen
                                    auch Weizen, Gerste, Mais, Früh-und Spätkartoffeln, Zucchini, Fisolen und Kraut an.
                                    Und zum Abschluss die weiße Rübe, mit der sich unser Kreis schließt, denn sie muss als letzte vom
                                    Feld.
                                </p>

                            </blockquote>
                        </div>
                    </div>
                </Container>

                <div className="parallax-container">
                    <Container id="txt-container">
                        <Row>
                            <Col>
                                <span className="my-text" data-aos="fade-down" data-aos-delay="300">Pflanzen</span>
                            </Col>
                            <Col>
                                <span className="my-text" data-aos="fade-down" data-aos-delay="500">Ernte</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <span className="my-text" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-delay="700" data-aos-offset="0">Wachstum</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <span className="my-text" data-aos="fade-up" data-aos-delay="900">Klima</span>
                            </Col>
                            <Col>
                                <span className="my-text" data-aos="fade-up" data-aos-delay="1100">Boden</span>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <div className="main-overview">
                    <Container>
                        <Row>
                            <Col lg="4">
                                <div className="overview-card" data-aos="fade-right" data-aos-delay="300">
                                    <FontAwesomeIcon icon={faMapMarkedAlt} className="icon-overview" />
                                    <h3 className="overview-heading">Regional</h3>
                                    <p>
                                        Wir bauen an, was auf unseren Feldern wächst. Unsere Produkte werden direkt von unseren Feldern
                                        im Umkreis von 3 km zum Hof geerntet.
                                        Als eines unserer regionalsten Produkte sehen wir die Erdbeere. Die Region um uns ist seit mehr als
                                        hundert Jahren für den Anbau der roten Frucht bekannt.
                                    </p>
                                </div>
                            </Col>
                            <Col lg="4">
                                <div className="overview-card" data-aos="fade-down" data-aos-delay="500">
                                    <FontAwesomeIcon icon={faClock} className="icon-overview" />
                                    <h3 className="overview-heading">Saisonal</h3>
                                    <p>
                                        Wir ernten im jährlichen Zyklus. Da wir ohne Gewächshäuser, direkt am Feld, im Wandel der
                                        Jahreszeiten arbeiten, ändert sich dementsprechend unser Angebot. Unser Produktkalender bietet
                                        eine Übersicht.
                                    </p>
                                </div>
                            </Col>
                            <Col lg="4">
                                <div className="overview-card" data-aos="fade-left" data-aos-delay="700">
                                    <FontAwesomeIcon icon={faBraille} className="icon-overview" />
                                    <h3 className="overview-heading">Vielfältig</h3>
                                    <p>
                                        Seit Jahren erweitern wir unser Angebot ausgehend vom ursprünglichen Ackerbau mit Weizen, Mais
                                        und Raps, im Sinne von Regionalität und saisonal, mit unseren neueren Spezialkulturen, wie Kürbissen,
                                        Fisolen, Kartoffeln und Kraut, sind wir noch nicht am Ende der Vielfalt angelangt.
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div >
            </div >

        );
    }
}

export default Home;