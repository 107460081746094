/* 
BRENNER Alexander, GRILL Alexander @ 2020

genussvomfeld Website
*/

//Dependencies
import React from 'react';

//Stylesheets and Bootstrap
import '../../Components_css/Footer/footer.css';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
//Components

class End extends React.Component {

    render() {
        return (
            <div className="footer">
                <Container>
                    <Row>
                        <Col lg="4">
                            <h4 className="display-4 footer-heading">Genuss vom Feld</h4>
                            <p className="footer-text">Regionale und Saisonale Produkte</p>
                        </Col>
                        <Col lg="4">
                            <h4 className="display-4 footer-heading">Kontakt</h4>
                            <a  id = "hov" href="/contact" className="footer-text">info@genussvomfeld.at</a>
                            <a id = "hov"  href="https://www.instagram.com/genussvomfeld" className="footer-text" target="_blank" >Instagram</a>
                            <p className="footer-text">Tel.: +43 664 5364094</p>
                        </Col>
                        <Col lg="4">
                            <h4 className="display-4 footer-heading">Impressum</h4>
                            <a id = "hov" href="/dsgvo" className="footer-text">Datenschutzerklärung</a>
                            <p className="footer-text">Aurelia Bierbaumer </p>
                            <p className="footer-text">7212 Forchtenstein</p>
                            <p className="footer-text">Web | Alexander Grill, Alexander Brenner</p>
                        </Col>
                    </Row>
                </Container>

                <div className="footer-disclaimer">
                    <h4 className="display-4 footer-heading">2020@ </h4>
                    <a href="https://reactjs.org/">React</a>
                    <a href="https://react-bootstrap.github.io/">Bootstrap for React</a>
                    <a href="https://michalsnik.github.io/aos/">  AOS</a>
                    <a href="https://developers.google.com/maps/documentation?hl=de">  Google Maps API</a>
                </div>
            </div>
        );
    }
}

export default End;