/* 
BRENNER Alexander, GRILL Alexander @ 2020

genussvomfeld Website
*/

//Dependencies
import React from 'react';
import { Container } from 'react-bootstrap';
import '../../Components_css/DSGVO/dsgvo.css';

class dsgvo extends React.Component {

    render() {
        return (
            <div className="prod-ctn-simple">
                <br></br>
                <h3>DATENSCHUTZERKLÄRUNG</h3>
                <br></br>
                <h4>Grundsätzliches</h4>
                <Container>
                    <p>
                        Der Schutz Ihrer persönlichen Daten ist uns ein besonderes Anliegen. Wir verarbeiten Ihre Daten daher ausschließlich auf Grundlage der gesetzlichen Bestimmungen (DSGVO, TKG 2003). In diesen Datenschutzinformationen informieren wir Sie über die wichtigsten Aspekte der Datenverarbeitung im Rahmen unserer Website. 
                        Beim Besuch unserer Webseite wird Ihre IP-Adresse, Beginn und Ende der Sitzung für die Dauer dieser Sitzung erfasst. Dies ist technisch bedingt und stellt damit ein berechtigtes Interesse iSv Art 6 Abs 1 lit f DSGVO dar. Soweit im Folgenden nichts anderes geregelt wird, werden diese Daten von uns nicht weiterverarbeitet.
                    </p>
                    <br></br>

                    <h6>Verantwortlicher (iS der DSGVO) ist:</h6>
                    <p>Aurelia Bierbaumer</p>
                    <p>Hochbergstraße 104, 7212 Forchtenstein</p>
                    <p>info@genussvomfeld.at</p>
                    <p>www.genussvomfeld.at</p>

                    <br></br>
                    <h6>Diese Website wird gehostet von:</h6>
                    <p>World4You Internet Services GmbH</p>
                    <p>Hafenstrasse 35, 4020 Linz - Austria</p>
                    <p>office@world4you.com</p>
                    <p>www.world4you.com</p>

                </Container>
                <br></br>
                <h4>Cookies</h4>
                <Container>
                    <p>
                        Diese Website verwendet keine Cookies.
                    </p>
                </Container>
                <br></br>
                <h4>Bewegungsdaten</h4>
                <Container>
                    <p>
                        Beim Besuch der Website werden (typischerweise) Daten durch den Auftragsverarbeiter aufgezeichnet (diese Standardprotokollierung ist vorgegeben und kann durch den Verantwortlichen nicht deaktiviert werden). Dies umfasst je aufgerufener Seite (Ressource) die IP-Adresse, Datum und Zeit, Browsertyp, Referrer sowie Art und Status des Aufrufs (Requests). Die IP-Adresse wird dabei bereits vor der Protokollierung anonymisiert und zwar derart, dass das letzte Byte (das sind die letzten drei Ziffern) der IP-Adresse verworfen wird.
                        Die anonymisierten Bewegungsdaten werden mit der Open-Source Webanalyse-Software AWStats (direkt im selben Rechenzentrum) ausgewertet und sind für den Verantwortlichen ausschließlich über eine sichere Verbindung (HTTPS inkl Authentifizierung) abrufbar.
                        Darüber hinaus findet keine weitere Aufzeichung, Weitergabe oder Auswertung der Bewegungsdaten statt.
                        Verarbeitungsgrundlage (iS der DSGVO) stellt ein berechtigtes Interesse des Verantwortlichender zur Präsentation der Leistungen und zur möglichen Information sowie Kontaktaufnahme für den Besucher/Interessenten dar (Art 6 Abs 1 lit f DSGVO).
                        Die Bewegungsdaten werden nach einer Frist von 2 Monaten gelöscht.
                    </p>
                </Container>
                <br></br>
                <h4>Kontakt mit uns</h4>
                <Container>
                    <p>
                        Sie können per Formular auf der Website oder per E-Mail Kontakt mit uns aufnehmen.
                        Wenn Sie das Formular benutzen, wird automatisch eine Email an den Verantwortlichen erstellt und zugestellt. Sämtliche Daten die Sie dabei angeben/übermitteln werden ausschließlich über eine sichere Verbindung übertragen (HTTPS) und sind daher für andere nicht einsehbar. Die Erstellung und Zustellung des Emails erfolgt dabei zur Gänze innherhalb des Rechenzentrums des Auftragsverarbeiters.
                        Sämtliche Emails werden durch den Verantwortlichen über eine sichere Verbindung ([START]TLS) abgerufen.
                        Verarbeitungsgrundlage (iS der DSGVO) sind ein berechtigtes Interesse des Verantwortlichender zur Kommunikation mit Interessenten (Art 6 Abs 1 lit f DSGVO) bzw die Durchführung vorvertraglicher Maßnahmen auf Anfrage der betroffenen Person (Art 6 Abs 1 lit b DSGVO).
                        Die Kontaktdaten werden zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen solange wie notwendig, maximal aber sechs Monate gespeichert.
                        Sämtliche Kontaktdaten geben wir nicht an Dritte weiter.
                    </p>
                </Container>
                <br></br>
                <h4>Ihre Rechte</h4>
                <Container>
                    <p>
                    Ihnen stehen bezüglich Ihrer bei uns gespeicherten Daten grundsätzlich die Rechte auf Auskunft, Berichtigung, Löschung, Einschränkung, Datenübertragbarkeit, Widerruf und Widerspruch zu. Gerne kommen wir der Erfüllung dieser Rechte schnellstmöglich (längstens aber binnen 1 Monats) nach (gegebenenfalls erlauben Sie uns dazu in Ihrem Interesse Ihre Identität zu prüfen).
                    </p>
                </Container>
            </div >
        );
    }


}

export default dsgvo;