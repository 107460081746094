/* 
BRENNER Alexander, GRILL Alexander @ 2020

genussvomfeld Website
*/

//Dependencies
import React from 'react';

//Stylesheets and Bootstrap
import { Container } from 'react-bootstrap';
import '../../Components_css/Product/product-simple.css';
import beetcalender from '../../img/calender/weiseRüben.png'

//Components

class Strawberry extends React.Component {

    render() {
        return (
            <div className="prod-ctn-simple">

                <div className="parallax-container-product-simple" id="beet"></div>
                <Container>
                    <div data-aos="fade-down" data-aos-delay="300">
                        <p className="display-4 prod-simple-head">Rüben</p>
                        <blockquote className="blockquote text-center">
                            <p className="mb-0 main-paragraph">
                            Um die Namensgebung der weißen Rüben scheiden sich die Geister. Umgangssprachlich werden sie bei uns weiße Rüben genannt, aber auch Synonyme wie Herbstrübe, Stoppelrübe, Mairübe oder Wasserrübe sind bekannt. Die Weiße Rübe gehört zur Pflanzenfamilie der Kreuzblütler und ist damit nicht verwandt mit der Roten Rübe, welche zur Familie der Fuchsschwanzgewächse gehört. Weiße Rüben sind unter guten Bedingungen sehr schnellwachsend. Wie manche Namen der Weißen Rübe andeuten (Mairübe), könnte durch eine frühe Pflanzung schon eine Ernte Ende Mai stattfinden. Bei uns werden die schmackhaften Rüben aber erst im Oktober angeboten, da sie bei uns als schmackhaftes Herbstgemüse geführt wird. 

                           </p>
                        </blockquote>
                    </div>

                    <div className="prod-calender">
                        <p className="display-4 prod-simple-head" id="saisontitle">Saisonkalender</p>
                        <img id="beetcalender" src={beetcalender} alt="beetcalender"></img>
                    </div>
                </Container>
            </div >
        );
    }


}

export default Strawberry;